import React from "react";
import { FaProjectDiagram, FaChartLine, FaMobileAlt } from "react-icons/fa";
import "./Projects.css";
import { motion } from "framer-motion";

const Projects = () => {
  // Define projects data
  const projects = [
    {
      icon: <FaProjectDiagram className="project-icon" />,
      title: "Financial Process Automation",
      description:
        "Automated financial and sustainability reporting using Python, Microsoft Fabrics, and Microsoft Power Platform.",
      client: "Heimstaden",
    },
    {
      icon: <FaChartLine className="project-icon" />,
      title: "Data Warehouse Migration",
      description:
        "Contributed to migrating data warehouse from on-premises to cloud using dbt and Snowflake.",
      client: "Deloitte Client",
    },
    {
      icon: <FaMobileAlt className="project-icon" />,
      title: "Healthcare App Prototype",
      description:
        "Developed a fully functional app prototype using Flutter for a health care service.",
      client: "VitalThings",
    },
    // Add more projects as needed
  ];

  // Define animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        delayChildren: 0.05,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 15 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    },
  };

  return (
    <motion.section
      className="projects-section"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="container">
        <h2 className="section-title">Our Projects</h2>
        <div className="projects-grid">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              className="project-item"
              variants={itemVariants}
            >
              <div className="project-icon-wrapper">{project.icon}</div>
              <h3 className="project-title">{project.title}</h3>
              <p className="project-description">{project.description}</p>
              <span className="project-client">Client: {project.client}</span>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Projects;
