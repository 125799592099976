import React from "react";
import { FaLightbulb, FaChartLine, FaHandshake } from "react-icons/fa";
import "./About.css";
import profileImage from "./image/profile.jpg";
import { motion } from "framer-motion";

const About = () => {
  // Define page transition variants with smoother animations
  const pageVariants = {
    initial: {
      opacity: 0,
      y: 15,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -15,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5, // Reduced duration
  };

  // Define variants with adjusted timings
  const sectionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5, // Reduced duration
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 15 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4, // Reduced duration
        ease: [0.43, 0.13, 0.23, 0.96], // Smooth easing
      },
    },
  };

  // Define the whyChooseUsData array
  const whyChooseUsData = [
    {
      icon: <FaLightbulb className="icon" />,
      title: "Expertise",
      text: "Our team brings years of industry experience and cutting-edge knowledge to every project.",
    },
    {
      icon: <FaChartLine className="icon" />,
      title: "Innovation",
      text: "We stay at the forefront of technological advancements to provide innovative solutions.",
    },
    {
      icon: <FaHandshake className="icon" />,
      title: "Client-Centric Approach",
      text: "We focus on delivering measurable outcomes tailored to our clients' unique needs.",
    },
  ];

  return (
    <motion.section
      className="about-section"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="container">
        <h2 className="section-title">About VL Consulting</h2>
        <p className="lead">
          VL Consulting, founded by an ex-Deloitte consultant, has been
          providing top-notch IT solutions for over a decade. Our team of
          experts is dedicated to helping businesses achieve their technology
          goals through innovative solutions.
        </p>

        {/* Why Choose Us Section */}
        <motion.div
          className="why-choose-container"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {whyChooseUsData.map((item, index) => (
            <motion.div
              key={index}
              className="why-choose-card"
              variants={cardVariants}
            >
              {item.icon}
              <h3 className="card-title">{item.title}</h3>
              <p className="card-text">{item.text}</p>
            </motion.div>
          ))}
        </motion.div>

        {/* Founder Section */}
        <div className="founder-section">
          <h3 className="section-title">Our Founder</h3>
          <div className="founder-card">
            <img
              src={profileImage}
              alt="Vladislav Levitin"
              className="founder-image"
            />
            <h4 className="founder-name">Vladislav Levitin</h4>
            <p className="founder-text">
              Vladislav Levitin, an ex-Deloitte consultant, founded VL
              Consulting with a vision to lead the industry in digital
              transformation and innovative solutions. With extensive experience
              in AI, data analytics, and technology management, Vladislav brings
              a wealth of knowledge to every client engagement.
            </p>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
