import React from "react";
import {
  FaChartBar,
  FaDatabase,
  FaRobot,
  FaCloud,
  FaCode,
  FaTools,
  FaAngleRight,
} from "react-icons/fa";
import "./Services.css";
import { motion } from "framer-motion"; // Import framer-motion for animations

const Services = () => {
  const services = [
    {
      title: "Data Analytics",
      description:
        "Unlock insights from your data to drive informed decision-making.",
      details:
        "Our data analytics services help you make data-driven decisions through:",
      offerings: [
        "Predictive analytics",
        "Business intelligence dashboards",
        "Data visualization",
        "Statistical modeling",
        "Big data processing",
      ],
      technologies: [
        "Python",
        "R",
        "Tableau",
        "Power BI",
        "Machine Learning",
        "Big Data",
        "SQL",
      ],
      icon: <FaChartBar className="service-icon" />,
    },
    {
      title: "Database Management",
      description:
        "Efficient data storage and retrieval solutions for your business.",
      details:
        "We design, implement, and maintain robust database systems to ensure your data is secure, accessible, and optimized for performance. Our services include:",
      offerings: [
        "Database design and architecture",
        "Data migration and integration",
        "Performance tuning",
        "Backup and recovery solutions",
        "Database security implementation",
      ],
      technologies: [
        "SQL",
        "NoSQL",
        "Oracle",
        "MySQL",
        "MongoDB",
        "PostgreSQL",
        "Redis",
        "Cassandra",
        "Elasticsearch",
      ],
      icon: <FaDatabase className="service-icon" />,
    },
    {
      title: "AI Integration",
      description:
        "Implement cutting-edge AI solutions to enhance your business processes.",
      details:
        "We help businesses leverage artificial intelligence to automate processes, gain insights, and improve decision-making. Our AI services include:",
      offerings: [
        "Machine learning model development",
        "AI-powered chatbots and virtual assistants",
        "Predictive maintenance systems",
        "Recommendation engines",
        "Anomaly detection systems",
      ],
      technologies: [
        "TensorFlow",
        "PyTorch",
        "Scikit-learn",
        "Natural Language Processing",
        "Computer Vision",
        "Deep Learning",
        "Reinforcement Learning",
        "GANs",
        "Transfer Learning",
      ],
      icon: <FaRobot className="service-icon" />,
    },
    {
      title: "Cloud Services",
      description: "Scalable and flexible cloud infrastructure solutions.",
      details:
        "We help businesses migrate to the cloud and optimize their cloud infrastructure for improved performance, scalability, and cost-efficiency. Our cloud services include:",
      offerings: [
        "Cloud migration strategies",
        "Infrastructure as Code (IaC)",
        "Serverless architecture implementation",
        "Cloud security and compliance",
        "Multi-cloud and hybrid cloud solutions",
      ],
      technologies: [
        "AWS",
        "Azure",
        "Google Cloud Platform",
        "Docker",
        "Kubernetes",
        "Serverless",
        "Microservices",
        "CI/CD",
        "Terraform",
      ],
      icon: <FaCloud className="service-icon" />,
    },
    {
      title: "Custom Software Development",
      description:
        "Tailored software solutions to meet your unique business needs.",
      details:
        "We design and develop custom software applications that align with your business objectives and streamline your operations. Our development services include:",
      offerings: [
        "Web application development",
        "Mobile app development (iOS and Android)",
        "Enterprise software solutions",
        "API development and integration",
        "Legacy system modernization",
      ],
      technologies: [
        "Java",
        "Python",
        "JavaScript",
        "React",
        "Node.js",
        "Angular",
        ".NET",
        "Vue.js",
        "Flutter",
      ],
      icon: <FaCode className="service-icon" />,
    },
    {
      title: "IT Consulting",
      description: "Expert technology guidance to drive your business forward.",
      details:
        "We provide strategic IT consulting to help businesses align their technology initiatives with their overall business goals. Our consulting services include:",
      offerings: [
        "IT strategy development",
        "Technology roadmap planning",
        "IT governance and compliance",
        "Vendor selection and management",
        "IT project management and oversight",
      ],
      technologies: [
        "IT Strategy",
        "Digital Transformation",
        "Agile Methodologies",
        "DevOps",
        "ITIL",
        "Lean IT",
        "Six Sigma",
        "COBIT",
        "TOGAF",
      ],
      icon: <FaTools className="service-icon" />,
    },
  ];

  // Define animation variants with refined timings
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05, // Reduced for minimal delay
        delayChildren: 0.05,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4, // Reduced duration
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    },
  };

  return (
    <motion.section
      className="services-section"
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <div className="container">
        <h2 className="section-title">Our Services</h2>
        <div className="service-grid">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="service-item"
              variants={itemVariants}
            >
              <div className="service-icon-wrapper">{service.icon}</div>
              <h3>{service.title}</h3>
              <p className="service-description">{service.description}</p>
              <div className="service-details">
                <p>{service.details}</p>
                <div className="service-offerings">
                  <h4>What we offer:</h4>
                  <ul>
                    {service.offerings.map((offering, i) => (
                      <li key={i}>
                        <FaAngleRight className="list-icon" />
                        {offering}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="service-technologies">
                  <h4>Technologies we use:</h4>
                  <div className="technology-tags">
                    {service.technologies.map((tech, i) => (
                      <span key={i} className="technology-tag">
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Services;
