import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaGithub } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer bg-dark text-light py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h5>VL Consulting</h5>
            <p>
              Transforming businesses through innovative technology solutions.
            </p>
          </div>
          <div className="col-md-6">
            <h5>Connect with us</h5>
            <div className="social-icons">
              <a href="#" className="me-3">
                <FaFacebook />
              </a>
              <a href="#" className="me-3">
                <FaTwitter />
              </a>
              <a href="#" className="me-3">
                <FaLinkedin />
              </a>
              <a href="#" className="me-3">
                <FaGithub />
              </a>
            </div>
          </div>
        </div>
        <hr className="mt-4" />
        <div className="text-center">
          <p>&copy; 2024 VL Consulting. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
