import React from "react";

import "./Contact.css";

const Contact = () => {
  return (
    <section className="contact-section">
      <div className="container">
        <h2 className="section-title">Get in Touch</h2>

        <p className="contact-description">
          We’d love to hear from you. Please fill out the form below and we’ll
          get back to you as soon as possible.
        </p>

        <form className="contact-form" action="#" method="POST">
          <div className="form-group">
            <label htmlFor="name">Your Name *</label>

            <input
              type="text"
              id="name"
              name="name"
              required
              placeholder="Enter your full name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Your Email *</label>

            <input
              type="email"
              id="email"
              name="email"
              required
              placeholder="Enter your email address"
            />
          </div>

          <div className="form-group">
            <label htmlFor="subject">Subject *</label>

            <input
              type="text"
              id="subject"
              name="subject"
              required
              placeholder="Enter subject"
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Your Message *</label>

            <textarea
              id="message"
              name="message"
              rows="6"
              required
              placeholder="Type your message here"
            ></textarea>
          </div>

          <button type="submit" className="btn-primary">
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
