import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FaChartBar,
  FaDatabase,
  FaRobot,
  FaCloud,
  FaCode,
  FaTools,
} from "react-icons/fa";
import "./Home.css";

const Home = () => {
  const [counts, setCounts] = useState({
    projects: 0,
    industries: 0,
    consultants: 0,
  });

  const targetCounts = {
    projects: 10,
    industries: 3,
    consultants: 1,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounts((prevCounts) => ({
        projects: Math.min(prevCounts.projects + 1, targetCounts.projects),
        industries: Math.min(
          prevCounts.industries + 1,
          targetCounts.industries
        ),
        consultants: Math.min(
          prevCounts.consultants + 1,
          targetCounts.consultants
        ),
      }));
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const services = [
    {
      icon: <FaChartBar className="service-icon" />,
      title: "Data Analytics",
      description: "Unlock insights from your data",
    },
    {
      icon: <FaDatabase className="service-icon" />,
      title: "Database Management",
      description: "Efficient data storage solutions",
    },
    {
      icon: <FaRobot className="service-icon" />,
      title: "AI Integration",
      description: "Implement AI in your business",
    },
    {
      icon: <FaCloud className="service-icon" />,
      title: "Cloud Services",
      description: "Scalable cloud infrastructure",
    },
    {
      icon: <FaCode className="service-icon" />,
      title: "Custom Software",
      description: "Tailored software solutions",
    },
    {
      icon: <FaTools className="service-icon" />,
      title: "IT Consulting",
      description: "Expert technology guidance",
    },
  ];

  return (
    <div className="home">
      <section className="hero">
        <div className="container">
          <h1>Welcome to VL Consulting</h1>
          <p>Innovative IT Solutions for Your Business</p>
          <Link to="/contact" className="cta-button">
            Get Started
          </Link>
        </div>
      </section>

      <div className="home-content">
        <section className="stats-section">
          <div className="container">
            <div className="stats-row">
              {Object.entries(counts).map(([key, value]) => (
                <div key={key} className="stat-item">
                  <div className="stat-box">
                    <h2>{value}+</h2>
                    <p>{key.charAt(0).toUpperCase() + key.slice(1)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="services">
          <div className="container">
            <h2 className="section-title">Our Services</h2>
            <div className="service-grid">
              {services.map((service, index) => (
                <div key={index} className="service-item">
                  <div className="service-icon-wrapper">{service.icon}</div>
                  <h3>{service.title}</h3>
                  <p className="service-description">{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="cta">
          <h2>Ready to transform your business?</h2>
          <Link to="/contact" className="cta-button pulse">
            Get Started
          </Link>
        </section>
      </div>
    </div>
  );
};

export default Home;
